import { GatsbyBrowser, WrapRootElementBrowserArgs } from 'gatsby';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});

export const WrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}: WrapRootElementBrowserArgs) => (
  <QueryClientProvider client={client}>{element}</QueryClientProvider>
);
