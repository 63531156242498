/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'swiper/css';
import 'swiper/css/pagination';

import { GatsbyBrowser } from 'gatsby';

import { WrapRootElement } from './src/commons/wrapRootElement';

export const wrapRootElement = WrapRootElement;

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = (
  {
    pathname,
  }) => {

  if (/\/spezialisten\/(\W+|\w+)*/.test(pathname)) {
    setTimeout(() => {
      window.scrollTo({ top: 0});
      document.querySelectorAll<HTMLAnchorElement>('#header-logo')[0].focus();
    }, 1000);

    return false;
  }

  return true;
};
